<template>
  <div>
    <i
        class="mr-2"
        :class="customData.field.iconClass"
    ></i>
    <b-title :class="customData.field.labelClass">
      {{ customData.field.label }}
    </b-title>
  </div>
</template>

<script>
export default {
  props: ['customData'],
}
</script>
